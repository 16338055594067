/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
	HttpClient,
	HttpHeaders,
	HttpParams,
	HttpResponse,
	HttpEvent,
	HttpParameterCodec,
	HttpContext,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { CreateOrgReqDto } from '../model/create-org-req-dto';
// @ts-ignore
import { CreateOrgResDto } from '../model/create-org-res-dto';
// @ts-ignore
import { Organization } from '../model/organization';
// @ts-ignore
import { UploadFileResDto } from '../model/upload-file-res-dto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
	providedIn: 'root',
})
export class OrganizationsService {
	protected basePath = 'http://localhost';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();
	public encoder: HttpParameterCodec;

	constructor(
		protected httpClient: HttpClient,
		@Optional() @Inject(BASE_PATH) basePath: string | string[],
		@Optional() configuration: Configuration
	) {
		if (configuration) {
			this.configuration = configuration;
		}
		if (typeof this.configuration.basePath !== 'string') {
			if (Array.isArray(basePath) && basePath.length > 0) {
				basePath = basePath[0];
			}

			if (typeof basePath !== 'string') {
				basePath = this.basePath;
			}
			this.configuration.basePath = basePath;
		}
		this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
	}

	/**
	 * @param consumes string[] mime-types
	 * @return true: consumes contains 'multipart/form-data', false: otherwise
	 */
	private canConsumeForm(consumes: string[]): boolean {
		const form = 'multipart/form-data';
		for (const consume of consumes) {
			if (form === consume) {
				return true;
			}
		}
		return false;
	}

	// @ts-ignore
	private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
		if (typeof value === 'object' && value instanceof Date === false) {
			httpParams = this.addToHttpParamsRecursive(httpParams, value);
		} else {
			httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
		}
		return httpParams;
	}

	private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
		if (value == null) {
			return httpParams;
		}

		if (typeof value === 'object') {
			if (Array.isArray(value)) {
				(value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
			} else if (value instanceof Date) {
				if (key != null) {
					httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
				} else {
					throw Error('key may not be null if value is Date');
				}
			} else {
				Object.keys(value).forEach(
					(k) =>
						(httpParams = this.addToHttpParamsRecursive(
							httpParams,
							value[k],
							key != null ? `${key}.${k}` : k
						))
				);
			}
		} else if (key != null) {
			httpParams = httpParams.append(key, value);
		} else {
			throw Error('key may not be null if value is not object or array');
		}
		return httpParams;
	}

	/**
	 * @param createOrgReqDto
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public organizationsControllerCreateOrganization(
		createOrgReqDto: CreateOrgReqDto,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<CreateOrgResDto>;
	public organizationsControllerCreateOrganization(
		createOrgReqDto: CreateOrgReqDto,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpResponse<CreateOrgResDto>>;
	public organizationsControllerCreateOrganization(
		createOrgReqDto: CreateOrgReqDto,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpEvent<CreateOrgResDto>>;
	public organizationsControllerCreateOrganization(
		createOrgReqDto: CreateOrgReqDto,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<any> {
		if (createOrgReqDto === null || createOrgReqDto === undefined) {
			throw new Error(
				'Required parameter createOrgReqDto was null or undefined when calling organizationsControllerCreateOrganization.'
			);
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = ['application/json'];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		// to determine the Content-Type header
		const consumes: string[] = ['application/json'];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/organizations`;
		return this.httpClient.request<CreateOrgResDto>('put', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			body: createOrgReqDto,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param oid
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public organizationsControllerDeleteOrganization(
		oid: string,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any>;
	public organizationsControllerDeleteOrganization(
		oid: string,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpResponse<any>>;
	public organizationsControllerDeleteOrganization(
		oid: string,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpEvent<any>>;
	public organizationsControllerDeleteOrganization(
		oid: string,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any> {
		if (oid === null || oid === undefined) {
			throw new Error(
				'Required parameter oid was null or undefined when calling organizationsControllerDeleteOrganization.'
			);
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/organizations/${this.configuration.encodeParam({
			name: 'oid',
			value: oid,
			in: 'path',
			style: 'simple',
			explode: false,
			dataType: 'string',
			dataFormat: undefined,
		})}`;
		return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public organizationsControllerGetAllOrganization(
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<Array<Organization>>;
	public organizationsControllerGetAllOrganization(
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpResponse<Array<Organization>>>;
	public organizationsControllerGetAllOrganization(
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpEvent<Array<Organization>>>;
	public organizationsControllerGetAllOrganization(
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<any> {
		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = ['application/json'];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/organizations`;
		return this.httpClient.request<Array<Organization>>('get', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public organizationsControllerGetCurrentOrganization(
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<Array<Organization>>;
	public organizationsControllerGetCurrentOrganization(
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpResponse<Array<Organization>>>;
	public organizationsControllerGetCurrentOrganization(
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpEvent<Array<Organization>>>;
	public organizationsControllerGetCurrentOrganization(
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<any> {
		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = ['application/json'];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/organizations/get/current`;
		return this.httpClient.request<Array<Organization>>('get', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param oid
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public organizationsControllerGetOrganization(
		oid: string,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<Organization>;
	public organizationsControllerGetOrganization(
		oid: string,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpResponse<Organization>>;
	public organizationsControllerGetOrganization(
		oid: string,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpEvent<Organization>>;
	public organizationsControllerGetOrganization(
		oid: string,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<any> {
		if (oid === null || oid === undefined) {
			throw new Error(
				'Required parameter oid was null or undefined when calling organizationsControllerGetOrganization.'
			);
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = ['application/json'];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/organizations/${this.configuration.encodeParam({
			name: 'oid',
			value: oid,
			in: 'path',
			style: 'simple',
			explode: false,
			dataType: 'string',
			dataFormat: undefined,
		})}`;
		return this.httpClient.request<Organization>('get', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param oid
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public organizationsControllerGetOrganizationMembers(
		oid: string,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<Array<string>>;
	public organizationsControllerGetOrganizationMembers(
		oid: string,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpResponse<Array<string>>>;
	public organizationsControllerGetOrganizationMembers(
		oid: string,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpEvent<Array<string>>>;
	public organizationsControllerGetOrganizationMembers(
		oid: string,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<any> {
		if (oid === null || oid === undefined) {
			throw new Error(
				'Required parameter oid was null or undefined when calling organizationsControllerGetOrganizationMembers.'
			);
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = ['application/json'];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/organizations/${this.configuration.encodeParam({
			name: 'oid',
			value: oid,
			in: 'path',
			style: 'simple',
			explode: false,
			dataType: 'string',
			dataFormat: undefined,
		})}/members`;
		return this.httpClient.request<Array<string>>('get', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param oid
	 * @param createOrgReqDto
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public organizationsControllerUpdateOrganization(
		oid: string,
		createOrgReqDto: CreateOrgReqDto,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any>;
	public organizationsControllerUpdateOrganization(
		oid: string,
		createOrgReqDto: CreateOrgReqDto,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpResponse<any>>;
	public organizationsControllerUpdateOrganization(
		oid: string,
		createOrgReqDto: CreateOrgReqDto,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpEvent<any>>;
	public organizationsControllerUpdateOrganization(
		oid: string,
		createOrgReqDto: CreateOrgReqDto,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any> {
		if (oid === null || oid === undefined) {
			throw new Error(
				'Required parameter oid was null or undefined when calling organizationsControllerUpdateOrganization.'
			);
		}
		if (createOrgReqDto === null || createOrgReqDto === undefined) {
			throw new Error(
				'Required parameter createOrgReqDto was null or undefined when calling organizationsControllerUpdateOrganization.'
			);
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		// to determine the Content-Type header
		const consumes: string[] = ['application/json'];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/organizations/${this.configuration.encodeParam({
			name: 'oid',
			value: oid,
			in: 'path',
			style: 'simple',
			explode: false,
			dataType: 'string',
			dataFormat: undefined,
		})}`;
		return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			body: createOrgReqDto,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param oid
	 * @param file
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public organizationsControllerUploadOrganizationLogo(
		oid: string,
		file: Blob,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<UploadFileResDto>;
	public organizationsControllerUploadOrganizationLogo(
		oid: string,
		file: Blob,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpResponse<UploadFileResDto>>;
	public organizationsControllerUploadOrganizationLogo(
		oid: string,
		file: Blob,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpEvent<UploadFileResDto>>;
	public organizationsControllerUploadOrganizationLogo(
		oid: string,
		file: Blob,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<any> {
		if (oid === null || oid === undefined) {
			throw new Error(
				'Required parameter oid was null or undefined when calling organizationsControllerUploadOrganizationLogo.'
			);
		}
		if (file === null || file === undefined) {
			throw new Error(
				'Required parameter file was null or undefined when calling organizationsControllerUploadOrganizationLogo.'
			);
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = ['application/json'];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		// to determine the Content-Type header
		const consumes: string[] = ['multipart/form-data'];

		const canConsumeForm = this.canConsumeForm(consumes);

		let localVarFormParams: { append(param: string, value: any): any };
		let localVarUseForm = false;
		let localVarConvertFormParamsToString = false;
		// use FormData to transmit files using content-type "multipart/form-data"
		// see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
		localVarUseForm = canConsumeForm;
		if (localVarUseForm) {
			localVarFormParams = new FormData();
		} else {
			localVarFormParams = new HttpParams({ encoder: this.encoder });
		}

		if (file !== undefined) {
			localVarFormParams = (localVarFormParams.append('file', <any>file) as any) || localVarFormParams;
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/organizations/${this.configuration.encodeParam({
			name: 'oid',
			value: oid,
			in: 'path',
			style: 'simple',
			explode: false,
			dataType: 'string',
			dataFormat: undefined,
		})}/logo`;
		return this.httpClient.request<UploadFileResDto>('post', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}
}
