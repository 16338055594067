import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorComponent } from './components/error/error.component';
import { MenuComponent } from './components/menu/menu.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { TagAttributePipe } from './pipes/tag-attribute.pipe';
import { ShowProfileComponent } from './components/show-profile/show-profile.component';
import { TagSetterComponent } from './components/tag-setter/tag-setter.component';
import { HasAnyAuthorityLevelDirective } from './directives/has-any-authority-level.directive';
import { HasAnyResourceAuthorityDirective } from './directives/has-any-resource-authority.directive';
import { SocialLinkPipe } from './pipes/social-link.pipe';
import { MatExpansionModule } from '@angular/material/expansion';
import { CropImgComponent } from './components/cropp-img/crop-img.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { QrModalComponent } from './components/qr-modal/qr-modal.component';
import { QRCodeModule } from 'angularx-qrcode';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogActions, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule } from '@angular/forms';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

@NgModule({
	declarations: [
		MenuComponent,
		SocialLinkPipe,
		ErrorComponent,
		ShowProfileComponent,
		LoadingSpinnerComponent,
		TagSetterComponent,
		TagAttributePipe,
		DateFormatPipe,
		HasAnyAuthorityLevelDirective,
		HasAnyResourceAuthorityDirective,
		CropImgComponent,
		QrModalComponent,
		ConfirmDialogComponent,
		SafeHtmlPipe,
	],
	exports: [
		MenuComponent,
		SocialLinkPipe,
		LoadingSpinnerComponent,
		ShowProfileComponent,
		TagAttributePipe,
		DateFormatPipe,
		HasAnyAuthorityLevelDirective,
		HasAnyResourceAuthorityDirective,
	],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		TranslateModule,
		MatToolbarModule,
		MatIconModule,
		MatExpansionModule,
		ImageCropperModule,
		QRCodeModule,
		MatCheckboxModule,
		MatInputModule,
		MatButtonModule,
		MatCardModule,
		MatMenuModule,
		MatSidenavModule,
		MatDialogTitle,
		MatDialogContent,
		MatDialogActions,
		MatButtonToggleModule,
	],
	providers: [SocialLinkPipe, SafeHtmlPipe],
})
export class SharedModule {}
