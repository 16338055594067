import { EventEmitter, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { firstValueFrom } from 'rxjs';
import { Profile, RolesService, User, UsersService } from '../modules/api';
import firebase from 'firebase/compat/app';
import { TokenService } from './token.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root',
})
export class AuthsService {
	public currentUser: firebase.User | null = null;
	public authorities: any | null = null;
	public authoritiesRefreshed: EventEmitter<void> = new EventEmitter();

	constructor(
		private auth: AngularFireAuth,
		private roleService: RolesService,
		private userService: UsersService,
		private snackBar: MatSnackBar,
		private tokenService: TokenService
	) {
		this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then();
		this.identify().then();
		setInterval(() => this.loadAuthToken(this.auth, this.snackBar), 30000);
	}

	async identify(user?: firebase.User | null): Promise<firebase.User | null> {
		if (user === undefined && !this.currentUser) {
			user = await firstValueFrom(this.auth.authState);
		}
		this.currentUser = user;
		await this.loadAuthToken(this.auth, this.snackBar);
		await this.loadAuthorities(user);

		return user;
	}

	async loadAuthorities(user?: firebase.User | null) {
		if (!user) {
			this.authorities = null;
			this.authoritiesRefreshed.emit();
			return;
		}

		const role = await firstValueFrom(this.roleService.rolesControllerGetUserRole(user.uid));
		this.authorities = role?.authorities || {};
		this.authoritiesRefreshed.emit();
	}

	async loadAuthToken(auth: AngularFireAuth, snackBar: MatSnackBar) {
		if (await auth?.currentUser) {
			try {
				this.tokenService.token = await (await auth.currentUser).getIdToken();
			} catch (e) {
				snackBar.open('Authentikációs hiba, frissítse az oldalt', 'X', { panelClass: 'error' });
			}
		}
	}

	login(email: string, password: string): Promise<firebase.User | null> {
		return this.auth
			.signInWithEmailAndPassword(email, password)
			.then((userCredential: firebase.auth.UserCredential) => this.identify(userCredential.user));
	}

	loginWithLink(email: string, link: string): Promise<firebase.User | null> {
		return this.auth
			.signInWithEmailLink(email, link)
			.then((userCredential: firebase.auth.UserCredential) => this.identify(userCredential.user));
	}

	async logout(): Promise<firebase.User | null> {
		await this.auth.signOut();

		return new Promise((res, rej) => {
			this.auth.signOut().then(() =>
				this.identify(null)
					.then((result) => {
						res(result);
					})
					.catch((e) => rej(e))
			);
		});
	}

	forgotPassword(email: string): Promise<void> {
		return this.auth.sendPasswordResetEmail(email);
	}

	async register(email: string, password: string, user: User, profile: Profile): Promise<any> {
		const userCredential = await this.auth.createUserWithEmailAndPassword(email, password);
		const fbUser: firebase.User = await this.identify(userCredential.user);

		await firstValueFrom(this.userService.usersControllerUpdateMyUser(user));
		await firstValueFrom(this.userService.usersControllerUpdateMyUser(profile));

		return fbUser;
	}

	isAuthenticated(): boolean {
		return !!this.currentUser && !this.currentUser?.isAnonymous;
	}

	async isAuthenticatedAsync() {
		if (!this.currentUser) {
			await this.identify();
		}

		return !!this.currentUser;
	}

	hasAnyAuthority(authorities: string[] | string): boolean {
		if (!this.authorities) {
			return false;
		}

		if (this.hasGeneralAdminAuthority()) {
			return true;
		}

		if (!Array.isArray(authorities)) {
			authorities = [authorities];
		}

		return Object.values(this.authorities).some((authority: string) => authorities.includes(authority));
	}

	async hasAnyAuthorityAsync(authorities: string[] | string): Promise<boolean> {
		if (!this.authorities || !this.currentUser) {
			await this.identify();
		}

		return this.hasAnyAuthority(authorities);
	}

	hasAnyResourceAuthority(resource: string, authorities: string[] | string): boolean {
		if (!this.authorities) {
			return false;
		}

		if (this.hasGeneralAdminAuthority()) {
			return true;
		}

		if (!Array.isArray(authorities)) {
			authorities = [authorities];
		}

		const generalAuth = this.authorities['*'];
		if (authorities.some((auth) => auth === generalAuth)) {
			return true;
		}

		if (!resource) {
			return false;
		}

		const rscAuth = this.authorities[resource];
		return authorities.some((auth) => auth === rscAuth);
	}

	hasGeneralAdminAuthority(): boolean {
		if (!this.authorities) {
			return false;
		}

		const generalAuth = this.authorities['*'];
		return generalAuth != null && generalAuth === 'admin';
	}
}
