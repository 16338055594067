export const environment = {
	firebase: {
		projectId: 'cardinmove-test',
		appId: '1:973594003788:web:f9f1e66562d7a17c9567e9',
		storageBucket: 'cardinmove-test.appspot.com',
		locationId: 'europe-west',
		apiKey: 'AIzaSyBoq-IlNlNSlRlbjuRe_7yl0ZQXKrXYNwk',
		authDomain: 'cardinmove-test.firebaseapp.com',
		messagingSenderId: '973594003788',
		measurementId: 'G-QX1YSLNDRP',
	},
	production: false,
	viewProfileURL: 'https://test.app.cardinmove.com/profile/view?uid=',
	registerURL: 'https://cardinmove-test.web.app/auth/register',
	purchaseURL: 'https://cardinmove-test.web.app',
	backendURL: 'https://cim.luna-space.com',
	// backendURL: 'http://localhost:3000',
};
