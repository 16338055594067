import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import videojs from 'video.js';
import 'videojs-youtube';
import { ReferralsComponent } from '../../../core/referrals/referrals.component';
import { SocialLinkPipe } from '../../pipes/social-link.pipe';
import {
	ExportsService,
	Link,
	Organization,
	OrganizationsService,
	Profile,
	RolesService,
	Theme,
	UsersService,
} from '../../../api';
import { firstValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import ProfileEnum = Link.ProfileEnum;
import { socialElements } from 'src/app/models/social-elements';
import { SocialElement } from 'src/app/models/social-element.model';
import { getRemoteConfig, getValue } from '@angular/fire/remote-config';
import { AuthsService } from 'src/app/services/auths.service';

@Component({
	selector: 'app-show-profile',
	templateUrl: './show-profile.component.html',
	styleUrls: ['./show-profile.component.scss'],
})
export class ShowProfileComponent implements OnInit, OnDestroy {
	@Input() uid?: string;
	@Input() profile?: Profile;
	@Input() chosenTags!: Map<number, boolean>;
	@Input() showTags = false;
	@Input() isMyProfile = false;
	@Input() isPublicView = false;
	@Input() theme: Theme;

	@Output() tagChange: EventEmitter<number[]> = new EventEmitter();

	organization: Organization;
	socialElements: SocialElement[] = socialElements;
	currentLang: string = this.ts.currentLang;
	generatedSignature?: any;
	signatureFeatureIsActive = false;

	isLoading = true;
	videoElement: any;
	currentUser: any;
	constructor(
		public dialog: MatDialog,
		public ts: TranslateService,
		private snackBar: MatSnackBar,
		public socialLinkPipe: SocialLinkPipe,
		public exportService: ExportsService,
		private orgService: OrganizationsService,
		private roleService: RolesService,
		private userService: UsersService,
		private auth: AuthsService
	) {
		this.currentUser = this.auth.currentUser;
	}

	async ngOnInit() {
		const remoteConfig = getRemoteConfig();
		remoteConfig.settings.minimumFetchIntervalMillis = 0;
		this.signatureFeatureIsActive = getValue(remoteConfig, 'signatureFeatureIsActive').asBoolean();

		this.isLoading = true;
		this.organization = undefined;
		console.log('Current language: ', this.ts.currentLang);
		this.generatedSignature = await firstValueFrom(
			this.userService.usersControllerGetSignatureForProfile(this.profile.profileId)
		);
		if (this.profile.profileId === ProfileEnum.Primary && this.uid) {
			try {
				Object.keys(
					(await firstValueFrom(this.roleService.rolesControllerGetUserRole(this.uid)))?.authorities || {}
				)
					.filter((o) => o !== '*')
					.map(async (orgID) => {
						this.organization = await firstValueFrom(
							this.orgService.organizationsControllerGetOrganization(orgID)
						);
					});
			} catch (e) {
				console.error(e);
			}
		}

		this.loadVideo();
		this.isLoading = false;
	}

	ngOnDestroy() {
		this.videoElement?.dispose();
	}

	onClick(): void {
		firstValueFrom(this.exportService.exportsControllerGetVCFCards(this.uid))
			.then((data) => {
				this.downLoadFile(data, 'text/vcard; charset=utf-8');
			})
			.catch(() => {
				this.snackBar.open('Something went wrong, please try again later.', 'X', {
					panelClass: 'error',
				});
			});
	}

	downLoadFile(data: any, type: string) {
		const blob = new Blob([data], { type });
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = this.profile.firstName + '_' + this.profile.lastName;
		anchor.href = url;
		anchor.click();
	}

	loadVideo() {
		if (!this.profile?.videoURL) {
			return;
		}

		const playerOptions = {
			autoplay: false,
			controls: true,
			preload: 'auto',
			techOrder: ['youtube'],
			// youtube: { ytControls: 1, modestbranding: 1, iv_load_policy: 1, origin: window.location.origin },
			youtube: { ytControls: 2 },
			sources: [{ type: 'video/youtube', src: this.profile.videoURL }],
		};

		try {
			this.videoElement = videojs(
				'profileVideo_' + this.uid,
				playerOptions,
				() => {
					console.log('onPlayerReady');
				},
				(err: any) => {
					console.log(err);
				}
			);
		} catch (e) {
			console.log('catch', e);
		}
	}

	parseBirthDate(birthDate?: any): string {
		if (!birthDate) {
			return '';
		}

		const dateString = this.profile.birthday.split('T')?.[0];

		if (dateString) {
			return new Date(dateString).toLocaleDateString(this.ts.currentLang);
		} else {
			return '';
		}
	}

	getShortUrl(url: string | undefined): string {
		if (url?.startsWith('http://') || url?.startsWith('https://')) {
			const urlParts = url.split('://');
			url = urlParts[1];
		}

		url = url.split('/')[0];

		return url;
	}

	getUrl(url: string | undefined): string {
		return url?.startsWith('http://') || url?.startsWith('https://') ? url : '//' + url;
	}

	showReferrals() {
		this.dialog.open(ReferralsComponent, {
			width: '1265px',
			maxWidth: '90%',
			height: '560px',
			data: this.uid,
		});
	}

	getLangItems(): any[] {
		return Object.keys(this.profile.languages)
			.filter((key) => this.profile.languages[key])
			.map((key) => ({
				key: this.ts.instant('shared.languages.' + key),
				value: this.profile.languages[key].name[this.ts.currentLang],
			}))
			.concat();
	}

	isUserVerified() {
		return (
			this.profile?.firstName &&
			this.profile?.lastName &&
			this.profile?.email &&
			this.profile?.cellPhone &&
			this.profile?.profileURL &&
			false // TODO: subscription validation
		);
	}

	isContactDetailsPresent(): boolean {
		return Boolean(
			this.profile?.chatBot ||
				this.profile?.questionnaire ||
				this.profile?.homeAddress ||
				this.profile?.cellPhone ||
				this.profile?.cellPhone2 ||
				this.profile?.cellPhone3 ||
				this.profile?.cellPhone4 ||
				this.profile?.whatsapp ||
				this.profile?.email ||
				this.profile?.email2 ||
				this.profile?.email3 ||
				this.profile?.birthday ||
				this.profile?.titleNew ||
				this.profile?.url ||
				this.profile?.notes
		);
	}

	isSocialLinkPresent(): boolean {
		return (
			this.socialElements &&
			this.socialElements.filter((socialElement) => this.profile[socialElement.field]).length > 0
		);
	}

	isOrgDetailsPresent(): boolean {
		return Boolean(
			this.profile.organization ||
				this.profile.orgAddress ||
				this.profile.orgTaxNumber ||
				this.profile.orgRegistrationNumber ||
				this.profile.mnbNumber
		);
	}

	isLanguagesPresent(): boolean {
		return this.profile.languages && Object.keys(this.profile.languages).length > 0;
	}

	copySignatureToClipboard() {
		const clipboardItem = new ClipboardItem({
			'text/html': new Blob([this.generatedSignature.signature], { type: 'text/html' }),
		});
		navigator.clipboard.write([clipboardItem]);
		this.snackBar.open(this.ts.instant('shared.copied'), '', {
			duration: 1000,
			panelClass: 'success',
		});
	}
}
